<template>
  <div class="form-content">
    <v-sheet
      color="white"
      elevation="2"
      class="tw-w-full tw-rounded"
    >
      <v-container class="tw-relative">
        <v-progress-linear
          :active="isProcessing"
          :indeterminate="isProcessing"
          absolute
          top
          color="grey darken-4"
          class="tw-rounded-t-xl"
        />
        <v-row>
          <v-col cols="12" class="tw-px-4 tw-pt-6 tw-pb-10">
            <validation-observer
              ref="observer"
              v-slot="{ invalid }"
            >
              <form @submit.prevent="doLogin">
                <validation-provider
                  v-slot="{ errors }"
                  name="username"
                  rules="required"
                >
                  <v-text-field
                    class="tw-text-xs"
                    :class="{'vee-error': errors.length}"
                    :value="form.username"
                    :error-messages="errors"
                    label="Username"
                    type="text"
                    placeholder="Username..."
                    outlined
                    required
                    @input="(x) => doUpdateData(x, 'username')"
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="password"
                  rules="required"
                >
                <!-- |min:8|verify_password -->
                  <div class="tw-flex tw-relative">
                    <v-text-field
                      :class="{'vee-error': errors.length}"
                      :value="form.password"
                      :error-messages="errors"
                      label="Password"
                      :type="showPassword ? 'text' : 'password'"
                      placeholder="Password..."
                      outlined
                      required
                      @input="(x) => doUpdateData(x, 'password')"
                    ></v-text-field>
                    <v-img
                      class="tw-absolute tw-right-3 tw-top-3.5 tw-cursor-pointer"
                      max-height="24"
                      max-width="24"
                      :src=" showPassword ? require('@/assets/icons/ic-eye-open.svg') :require('@/assets/icons/ic-eye-close.svg')"
                      @click="showPassword = !showPassword"
                    />
                  </div>
                </validation-provider>
                <div>
                  <v-btn
                    class="tw-w-full tw-mb-3"
                    color="primary"
                    type="submit"
                    large
                    :disabled="invalid"
                  >
                    Sign In
                  </v-btn>
                  <small class="tw-block">
                    <TextNormal>
                      <router-link class="tw-underline tw-text-sm" :to="{ name: ROUTE_RESET_PASSWORD }">
                        Forgot your password ?
                      </router-link>
                    </TextNormal>
                  </small>
                </div>
                <!-- <v-btn @click="clear">
                  clear
                </v-btn> -->
                <div class="tw-flex tw-my-4 tw-items-center">
                  <v-divider />
                  <small class="tw-uppercase tw-mx-4 tw-text-xs">
                    <TextNormal>Or sign in with </TextNormal>
                  </small>
                  <v-divider />
                </div>
                <div class="button-container">
                  <div class="custom-social-signin custom-social-signin tw-flex tw-justify-center">
                    <v-btn
                      large
                      outlined
                      class="tw-border-r-0 tw-rounded-r-none"
                      style="border-color: #4588F1;"
                    >
                      <v-img
                        max-height="29"
                        max-width="33"
                        src="@/assets/icons/ic_google.png"
                      />
                    </v-btn>
                    <v-btn
                      large
                      outlined
                      class="tw-rounded-l-none tw-text-white"
                      style="background: #4588F1; border-color: #4588F1;"
                    >
                      Log in with Google
                    </v-btn>
                  </div>
                </div>
              </form>
            </validation-observer>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>

//utilities
import {
  ROUTE_SIGNUP,
  ROUTE_RESET_PASSWORD,
} from '@/constants'
import TextNormal from '@/components/atoms/TextNormal'

export default {
  name : 'SignInForm',
  components: {
    TextNormal,
  },
  props: [
    'form',
    'doLogin',
    'doUpdateData',
    'isProcessing'
  ],

  data: () => ({
    //not state management data
    ROUTE_SIGNUP: ROUTE_SIGNUP,
    ROUTE_RESET_PASSWORD: ROUTE_RESET_PASSWORD,
    showPassword: false,
    types: ['customer', 'employee'],
  }),

}
</script>

<style lang="sass" scope>
  .v-label
    font-size: 14px !important
</style>